import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { PipesModule } from '../pipes/pipes.module';
import { DealNotFoundComponent } from './components/deal-not-found/page-not-found.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LoadFailedComponent } from './components/load-failed/load-failed.component';
import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';

@NgModule({
  imports: [CommonModule, MaterialModule, PipesModule],
  declarations: [
    ErrorPageComponent,
    PageNotFoundComponent,
    LoginFailedComponent,
    LoadFailedComponent,
    DealNotFoundComponent,
    UnderMaintenanceComponent
  ],
  exports: [
    PageNotFoundComponent,
    LoginFailedComponent,
    LoadFailedComponent,
    DealNotFoundComponent,
    UnderMaintenanceComponent
  ]
})
export class ErrorPageModule {}
