import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, defer, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { MessageService } from 'src/app/modules/message/services/message.service';

export interface ApiFailureResponse {
  message?: string;
  target?: string;
  details?: ApiFailureResponse[];
}

export class ApiResponse<T> {
  constructor(public data: T, public failure: ApiFailureResponse = null, public message?: string) {}

  static ok<T>(data: T): ApiResponse<T> {
    return new ApiResponse(data);
  }

  static failure<T>(failure: ApiFailureResponse, message?: string): ApiResponse<T> {
    return new ApiResponse(null, failure, message);
  }

  public isOk(): boolean {
    return this.failure === null;
  }
}

declare type ApiOptions = {
  body?: any;
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  reportProgress?: boolean;
  observe?: 'body' | 'events' | 'response';
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json';
  withCredentials?: boolean;
  defaultFailureMessage?: string;
  hideFailureMessage?: boolean;
  skipIsProcessing?: boolean;
};

@Injectable()
export abstract class BaseService<T = any> implements OnDestroy {
  protected readonly _destroy = new Subject<void>();
  protected readonly _url: string;
  protected readonly _isProcessing = new BehaviorSubject<number>(0);
  protected readonly _isBaseDataLoaded = new Subject<boolean>();
  protected _baseDataLoaded = false;
  protected _baseData: T | undefined;

  public readonly isProcessing$ = this._isProcessing.asObservable().pipe(map((count) => count > 0));

  public readonly isBaseDataLoaded$ = this._isBaseDataLoaded.asObservable();

  get baseData() {
    return this._baseData;
  }

  get baseDataLoaded() {
    return this._baseDataLoaded;
  }

  constructor(
    protected _http: HttpClient,
    protected _messageService: MessageService,
    @Inject(String) apiRootUrl: string
  ) {
    this._url = apiRootUrl;
  }

  public ngOnDestroy(): void {
    this._isProcessing.complete();
    this._isBaseDataLoaded.complete();
    this._destroy.next();
    this._destroy.complete();
  }

  public getBaseData(): Observable<ApiResponse<T> | null> {
    return of(null);
  }

  protected _setupBaseData(data: T): void {
    if (data) {
      this._baseData = data;
      this._baseDataLoaded = true;
      this._isBaseDataLoaded.next(true);
    }
  }

  protected _get<R>(route: string, options?: ApiOptions): Observable<ApiResponse<R>> {
    return this._request<R>('GET', route, options);
  }

  protected _post<R>(route: string, body: any, options?: ApiOptions): Observable<ApiResponse<R>> {
    return this._request<R>('POST', route, this._buildApiOptions(body, options));
  }

  protected _put<R>(route: string, body: any, options?: ApiOptions): Observable<ApiResponse<R>> {
    return this._request<R>('PUT', route, this._buildApiOptions(body, options));
  }

  protected _patch<R>(route: string, body: any, options?: ApiOptions): Observable<ApiResponse<R>> {
    return this._request<R>('PATCH', route, this._buildApiOptions(body, options));
  }

  protected _delete<R>(route: string, options?: ApiOptions): Observable<ApiResponse<R>> {
    return this._request<R>('DELETE', route, options);
  }

  protected _parseFailureResponse(failureResponse: ApiFailureResponse, defaultMessage = 'Your request failed'): string {
    const messages: string[] = [];
    if (failureResponse?.message) {
      messages.push(failureResponse.message);
    }
    failureResponse?.details?.map((failure) => {
      if (failure.message) {
        messages.push(failure.message);
      }
    });

    const message = messages.join('\n');
    return message || defaultMessage;
  }

  protected _startRequest(): void {
    this._isProcessing.next(this._isProcessing.value + 1);
  }

  protected _endRequest(): void {
    this._isProcessing.next(Math.max(this._isProcessing.value - 1, 0));
  }

  private _request<R>(method: string, route: string, options?: ApiOptions): Observable<ApiResponse<R>> {
    let url = this._url;
    url += `/${route}`;

    return defer(() => {
      if (!options?.skipIsProcessing) {
        this._startRequest();
      }

      return this._http.request<R>(method, url, options as any).pipe(
        map((data: any) => ApiResponse.ok<R>(data)),
        catchError((response: HttpErrorResponse) => {
          const message = this._parseFailureResponse(response.error, options?.defaultFailureMessage);

          if (!options?.hideFailureMessage) {
            this._messageService.showMessage({ isError: true, content: message });
          }

          return of(ApiResponse.failure<R>(response.error || response.message, message));
        }),
        finalize(() => {
          if (!options?.skipIsProcessing) {
            this._endRequest();
          }
        })
      );
    });
  }

  private _buildApiOptions(body: any, options?: ApiOptions): ApiOptions {
    return {
      body,
      headers: options?.headers,
      observe: options?.observe,
      params: options?.params,
      reportProgress: options?.reportProgress,
      responseType: options?.responseType,
      withCredentials: options?.withCredentials,
      defaultFailureMessage: options?.defaultFailureMessage,
      hideFailureMessage: options?.hideFailureMessage
    };
  }
}
