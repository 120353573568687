<document-explorer-toolbar
  [profileDisplayName]="(documentExplorerService.profileData$ | async)?.profileDisplayName"
  [base64ProfileImage]="(documentExplorerService.profileData$ | async)?.base64ProfileImage"
  [profileInitials]="(documentExplorerService.profileData$ | async)?.profileInitials"
></document-explorer-toolbar>

<div class="content-container">
  <div class="document-browser-container">
    <document-explorer-deal-info
      [dealName]="(documentExplorerService.dealData$ | async)?.dealName"
      [loanNumber]="(documentExplorerService.dealData$ | async)?.loanNumber"
    >
    </document-explorer-deal-info>

    <div class="document-browser-selection-container">
      <document-explorer-category-list
        [categories]="documentExplorerService.categories$ | async"
        [disabled]="(overviewMode$ | async) !== documentTemplateSelectionEnum"
        [selectedCategoryId]="selectedCategoryId$ | async"
        (selectedCategoryIdChanged)="selectedCategoryIdChanged($event)"
      >
      </document-explorer-category-list>

      <document-explorer-document-template-list
        [categoryName]="getSelectedCategoryName(selectedCategoryId$ | async)"
        [documentTemplates]="documentTemplates"
        [disabled]="(overviewMode$ | async) !== documentTemplateSelectionEnum"
        [selectedCategoryId]="selectedCategoryId$ | async"
        [documentTemplateSelectionByCategories]="documentTemplateSelectionByCategories"
        (onDocumentTemplateWarningClickEvent)="onDocumentTemplateWarningClick($event)"
        (documentTemplateSelectionByCategoriesChanged)="documentTemplates = $event"
      >
      </document-explorer-document-template-list>
    </div>
  </div>

  <div class="document-selection-overview-container">
    <div class="document-selection-list-container">
      <div class="document-selection-list-header">
        <ng-container [ngSwitch]="overviewMode$ | async">
          <h3 matSubheader *ngSwitchCase="documentTemplateSelectionEnum">
            Selected Documents ({{ getTotalDocumentsSelectedCount() }})
          </h3>
          <h3 matSubheader *ngSwitchCase="generatedDocumentsEnum">Generated Documents</h3>
          <h3 matSubheader *ngSwitchCase="generatedDocumentsHistoryEnum">Generated Document History</h3>
        </ng-container>
        <button
          *ngIf="(overviewMode$ | async) === documentTemplateSelectionEnum"
          [disabled]="!hasDocumentsSelected"
          mat-button
          [class.disabled]="(overviewMode$ | async) !== documentTemplateSelectionEnum || !hasDocumentsSelected"
          (click)="resetDocumentTemplateSelection()"
          aria-label="Remove all selected documents."
        >
          Remove All
        </button>
      </div>

      <ng-container *ngIf="(isProcessing$ | async) || (downloadId$ | async) !== null; else notProcessing">
        <div class="document-selection-spinner" @fadeInAnimation>
          <mat-progress-spinner color="primary" mode="indeterminate" value="50"></mat-progress-spinner>
          <span class="document-selection-spinner-message">{{ generatingDocumentsMessage$ | async }}</span>
        </div>
      </ng-container>

      <ng-template #notProcessing>
        <ng-container
          *ngIf="
            (overviewMode$ | async) === documentTemplateSelectionEnum ||
            (overviewMode$ | async) === generatedDocumentsEnum
          "
        >
          <ng-container *ngIf="hasDocumentsSelected; else noDocumentsSelected">
            <div class="document-selection-list" @fadeInAnimation>
              <document-explorer-overview-expansion-panel
                *ngFor="let item of getDocumentTemplateSelectionByCategoriesForDownloadDisplay()"
                [expanded]="item.panelOpenState"
                [title]="getSelectedCategoryName(item.categoryId)"
              >
                <ng-container
                  *ngIf="
                    (overviewMode$ | async) === generatedDocumentsEnum && (generatedDocuments$ | async)?.length > 0;
                    else inSelectionListMode
                  "
                >
                  <mat-list @fadeInOutAnimation>
                    <mat-list-item
                      *ngFor="
                        let documentTemplate of sortSelectedDocumentTemplates(item.selectedDocumentTemplates.selected)
                      "
                    >
                      <span matListItemTitle class="generated-document">
                        <ng-container
                          *ngIf="
                            getGeneratedDocumentUrlByDocumentTemplate(documentTemplate) as generatedDocument;
                            else noUrl
                          "
                        >
                          <a class="generated-document-link" download [href]="generatedDocument.url">{{
                            generatedDocument?.label
                          }}</a>
                          <span><mat-icon class="generated-document-success-icon">check_circle</mat-icon></span>
                        </ng-container>
                        <ng-template #noUrl>
                          <span class="generated-document-label">{{ documentTemplate?.label }}</span>
                          <span><mat-icon color="warn" matTooltip="Failed to generate file.">error</mat-icon></span>
                        </ng-template>
                      </span>
                    </mat-list-item>
                  </mat-list>
                </ng-container>

                <ng-template #inSelectionListMode>
                  <mat-selection-list
                    (selectionChange)="selectedDocumentTemplatesChangedByCategoryId(item.categoryId, $event)"
                    @fadeInOutAnimation
                  >
                    <mat-list-option
                      *ngFor="
                        let documentTemplate of sortSelectedDocumentTemplates(item.selectedDocumentTemplates.selected)
                      "
                      [selected]="isDocumentTemplateSelectedByCategory(item.categoryId, documentTemplate)"
                      [value]="documentTemplate"
                      color="primary"
                      [disabled]="(overviewMode$ | async) !== documentTemplateSelectionEnum"
                    >
                      <span class="document-template-label">{{ documentTemplate?.label }}</span>
                    </mat-list-option>
                  </mat-selection-list>
                </ng-template>
              </document-explorer-overview-expansion-panel>
            </div>
          </ng-container>

          <ng-template #noDocumentsSelected>
            <div class="no-documents-selected-info-text" @fadeInAnimation>
              <div>
                <mat-icon>download</mat-icon>
                <span>Please select documents from the list to download</span>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="(overviewMode$ | async) === generatedDocumentsHistoryEnum">
          <div class="document-selection-list" @fadeInAnimation>
            <document-explorer-overview-expansion-panel
              *ngFor="let historicalGeneratedDocumentGroup of historicalGeneratedDocumentGroups$ | async"
              [expanded]="historicalGeneratedDocumentGroup.panelOpenState"
              [title]="historicalGeneratedDocumentGroup.dateUtc | date : 'M/d/yy - h:mm a'"
            >
              <mat-list @fadeInOutAnimation>
                <mat-list-item *ngFor="let generatedDocument of historicalGeneratedDocumentGroup.generatedDocuments">
                  <span matListItemTitle class="generated-document">
                    <ng-container *ngIf="!generatedDocument.hasError; else generatedDocumentHasError">
                      <a class="generated-document-link" download [href]="generatedDocument?.url">{{
                        generatedDocument?.label
                      }}</a>
                      <span><mat-icon class="generated-document-success-icon">check_circle</mat-icon></span>
                    </ng-container>
                    <ng-template #generatedDocumentHasError>
                      <span class="generated-document-label">{{ generatedDocument?.label }}</span>
                      <span
                        ><mat-icon
                          color="warn"
                          matTooltip="There was an error generating this document. Please try again. If the failure repeats please contact the IT Help Desk."
                          >error</mat-icon
                        ></span
                      >
                    </ng-template>
                  </span>
                </mat-list-item>
              </mat-list>
            </document-explorer-overview-expansion-panel>
          </div>
        </ng-container>
      </ng-template>
    </div>

    <document-explorer-overview-action-buttons
      [overviewMode]="overviewMode$ | async"
      [documentGenerationButtonDisabled]="!hasDocumentsSelected"
      [documentGenerationResetButtonDisabled]="(isProcessing$ | async) || (downloadId$ | async) !== null"
      [documentGenerationHistoryButtonsDisabled]="(isProcessing$ | async) || (downloadId$ | async) !== null"
      (onGenerateDocumentsButtonEvent)="generateDocuments()"
      (onDocumentGenerationResetEvent)="resetGeneratedDocumentSelection()"
      (onShowDocumentGenerationHistoryEvent)="showDocumentGenerationHistory()"
      (onCloseDocumentGenerationHistoryEvent)="closeDocumentGenerationHistory()"
    >
    </document-explorer-overview-action-buttons>
  </div>
</div>
