import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentExplorerServiceBaseData } from '../models/document-explorer-service-base-data.model';
import { DocumentExplorerService } from '../services/document-explorer.service';

@Injectable()
export class DocumentExplorerBaseDataResolver implements Resolve<DocumentExplorerServiceBaseData> {
  constructor(private _documentExplorerService: DocumentExplorerService, private _router: Router) {}

  public resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<DocumentExplorerServiceBaseData> {
    if (this._documentExplorerService.baseData) {
      return of(this._documentExplorerService.baseData);
    } else {
      const dealId: any = _route.params['id'];

      const dealIdAsNumber: number = Number(dealId);

      if (isNaN(+dealIdAsNumber)) {
        this._router.navigate(['/deal-not-found'], {
          replaceUrl: true,
          queryParams: { detailedErrorMessage: `You provided an invalid value '${dealId}' as the deal id.` }
        });
        return null;
      }

      return this._documentExplorerService.getBaseDataWithId(dealId).pipe(
        map((response) => {
          if (response) {
            return response;
          } else {
            this._router.navigate(['/load-failed'], { replaceUrl: true });
            return null;
          }
        })
      );
    }
  }
}
