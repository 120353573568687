import { Directive, Inject, InjectionToken, OnDestroy, Optional } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from './base-component';

@Directive({
  selector: 'base-component-with-is-processing'
})
export abstract class BaseComponentWithIsProcessing extends BaseComponent implements OnDestroy {
  /** A behavior subject holding a value to indicate if the component is processing. */
  protected readonly _isProcessing = new BehaviorSubject<boolean>(false);
  /** Observable that returns a value indicating if the component is processing. */
  public readonly isProcessing$: Observable<boolean> = this._isProcessing.asObservable();

  /** Returns a value indicating if the component is processing. */
  get isProcessing() {
    return this._isProcessing.getValue();
  }

  constructor(@Optional() @Inject(InjectionToken) public externalIsProcessingObservables: Observable<boolean>[]) {
    super();

    externalIsProcessingObservables.forEach(externalIsProcessingObservable => {
      externalIsProcessingObservable.pipe(takeUntil(this._destroy)).subscribe(isProcessing => this._isProcessing.next(isProcessing));
    });
  }

  public override ngOnDestroy(): void {
    this._isProcessing.complete();
    super.ngOnDestroy();
  }
}
