import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, BaseService } from '../../../shared/base-services/base-service.service';
import { MessageService } from '../../message/services/message.service';
import { ProfileDataResponse } from '../models/profile-data-response.model';

/** A service for interacting with the Azure Static Web App './auth' endpoint(s). */
@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends BaseService<void> {
  public constructor(_http: HttpClient, _messageService: MessageService) {
    super(_http, _messageService, '/.auth');
  }

  /** Get the profile data about the signed in user that the Azure Static Web App provides on the 'me' endpoint. */
  public getProfileData(hideFailureMessage: boolean = false): Observable<ApiResponse<ProfileDataResponse>> {
    return this._get<ProfileDataResponse>('me', {
      hideFailureMessage: hideFailureMessage,
      defaultFailureMessage: 'An error occured while attempting to load the user profile data.'
    });
  }
}
