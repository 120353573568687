import { Component, HostBinding } from '@angular/core';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @HostBinding('class') readonly className = 'message';

  public constructor(public messageService: MessageService) {}

  public onDismissMessageClick(): void {
    this.messageService.dismissMessage();
  }
}
