import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { PipesModule } from '../pipes/pipes.module';
import { DocumentExplorerCategoryListComponent } from './components/document-explorer-category-list/document-explorer-category-list.component';
import { DocumentExplorerDealInfoComponent } from './components/document-explorer-deal-info/document-explorer-deal-info.component';
import { DocumentExploreDocumentTemplateListComponent } from './components/document-explorer-document-template-list/document-explorer-document-template-list.component';
import { DocumentExplorerOverviewActionButtons } from './components/document-explorer-overview-action-buttons/document-explorer-overview-action-buttons.component';
import { DocumentExplorerOverviewExpansionPanelComponent } from './components/document-explorer-overview-expansion-panel/document-explorer-overview-expansion-panel.component';
import { DocumentExplorerToolbarComponent } from './components/document-explorer-toolbar/document-explorer-toolbar.component';
import { DocumentExplorerComponent } from './components/document-explorer/document-explorer.component';
import { DocumentTemplateWarningDialogComponent } from './components/document-template-warning-dialog/document-template-warning-dialog.component';
import { DocumentExplorerBaseDataResolver } from './resolvers/document-explorer-base-data.resolver';

@NgModule({
  imports: [CommonModule, MaterialModule, PipesModule],
  declarations: [
    DocumentExplorerToolbarComponent,
    DocumentExplorerDealInfoComponent,
    DocumentExplorerCategoryListComponent,
    DocumentExploreDocumentTemplateListComponent,
    DocumentExplorerOverviewExpansionPanelComponent,
    DocumentExplorerOverviewActionButtons,
    DocumentTemplateWarningDialogComponent,
    DocumentExplorerComponent
  ],
  exports: [DocumentExplorerComponent],
  providers: [DocumentExplorerBaseDataResolver]
})
export class DocumentExplorerModule {}
