<div class="overview-action-buttons-container">
  <div class="document-generation-action-button-container">
    <button
      *ngIf="overviewMode === documentTemplateSelectionEnum"
      mat-raised-button
      color="primary"
      [disabled]="documentGenerationButtonDisabled"
      (click)="onGenerateDocumentsButtonClick()"
      aria-label="Generate documents for selected files."
    >
      Generate documents
    </button>

    <button
      *ngIf="overviewMode === generatedDocumentsEnum"
      mat-raised-button
      color="primary"
      [disabled]="documentGenerationResetButtonDisabled"
      (click)="onDocumentGenerationResetClick()"
      aria-label="Start the document selection over again."
    >
      Start over
    </button>
  </div>

  <div class="document-generation-history-action-button-container">
    <ng-container *ngIf="overviewMode !== generatedDocumentsHistoryEnum; else generatedFileHistoryMode">
      <button
        mat-mini-fab
        [disabled]="documentGenerationHistoryButtonsDisabled"
        (click)="onShowDocumentGenerationHistoryClick()"
        aria-label="Show generated file download history"
      >
        <mat-icon>history</mat-icon>
      </button>
    </ng-container>

    <ng-template #generatedFileHistoryMode>
      <button
        mat-mini-fab
        [disabled]="documentGenerationHistoryButtonsDisabled"
        (click)="onCloseDocumentGenerationHistoryClick()"
        aria-label="Close the generated file download history and go back"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </ng-template>
  </div>
</div>
