export class StringUtilities {
  public static trimFirst(text: string, trimVal: string): string {
    return text.startsWith(trimVal) ? text.substring(trimVal.length) : text;
  }

  public static trimLast(text: string, trimVal: string): string {
    const trimLength = text.length - trimVal.length;
    return text.endsWith(trimVal) ? text.substring(0, trimLength >= 0 ? trimLength : text.length) : text;
  }

  public static IsNullOrUndefinedOrEmpty(text: string) {
    return text === null || text === undefined || text === '';
  }
}
