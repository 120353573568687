import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { DocumentExplorerComponent } from './modules/document-explorer/components/document-explorer/document-explorer.component';
import { DocumentExplorerBaseDataResolver } from './modules/document-explorer/resolvers/document-explorer-base-data.resolver';
import { DealNotFoundComponent } from './modules/error-page/components/deal-not-found/page-not-found.component';
import { LoadFailedComponent } from './modules/error-page/components/load-failed/load-failed.component';
import { LoginFailedComponent } from './modules/error-page/components/login-failed/login-failed.component';
import { PageNotFoundComponent } from './modules/error-page/components/page-not-found/page-not-found.component';
import { UnderMaintenanceComponent } from './modules/error-page/components/under-maintenance/under-maintenance.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'page-not-found'
  },
  {
    path: 'deal/:id',
    component: DocumentExplorerComponent,
    resolve: {
      baseData: DocumentExplorerBaseDataResolver
    },
    data: { animation: 'documentExplorer' }
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
    data: { animation: 'loginFailed' }
  },
  {
    path: 'load-failed',
    component: LoadFailedComponent,
    data: { animation: 'loadFailed' }
  },
  {
    path: 'deal-not-found',
    component: DealNotFoundComponent,
    data: { animation: 'dealNotFound' }
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    data: { animation: 'pageNotFound' }
  },
  {
    path: 'under-maintenance',
    component: UnderMaintenanceComponent,
    data: { animation: 'underMaintenance' }
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
