import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Category } from '../../models/category.model';

@Component({
  selector: 'document-explorer-category-list',
  templateUrl: './document-explorer-category-list.component.html',
  styleUrls: ['./document-explorer-category-list.component.scss']
})
export class DocumentExplorerCategoryListComponent implements OnDestroy {
  @HostBinding('class') readonly className = 'document-explorer-category-list';

  @Input()
  public categories: Category[];

  @Input()
  public disabled: boolean;

  @Input()
  public selectedCategoryId: number;

  @Output()
  public selectedCategoryIdChanged: EventEmitter<number> = new EventEmitter<number>();

  public selectionChange(event: MatSelectionListChange): void {
    const categoryId: number | null = event?.options[0]?.value;
    this.selectedCategoryIdChanged.emit(categoryId);
  }

  public getDocumentTemplateCountByCategoryId(categoryId: number): number {
    return this.categories?.filter((f) => f.id === categoryId)[0]?.documentTemplates?.length;
  }

  public ngOnDestroy(): void {
    this.selectedCategoryIdChanged.complete();
  }
}
