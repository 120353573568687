import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { SpinnerOverlayComponent } from './components/spinner-overlay-component/spinner-overlay.component';

@NgModule({
  imports: [OverlayModule, CommonModule, MaterialModule],
  declarations: [SpinnerOverlayComponent],
  entryComponents: [SpinnerOverlayComponent]
})
export class SpinnerOverlayModule {}
