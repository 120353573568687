<div class="categories-container">
  <div class="categories-label">
    <span>Categories:</span>
  </div>
  <mat-selection-list multiple="false" (selectionChange)="selectionChange($event)">
    <ng-container *ngIf="categories?.length > 0; else noCategories">
      <mat-list-option
        color="accent"
        *ngFor="let category of categories"
        [value]="category.id"
        [selected]="selectedCategoryId === category.id"
        [disabled]="disabled"
      >
        <mat-icon matListItemIcon>folder</mat-icon>
        <span matListItemTitle class="category-label">
          <span>{{ category.label }}</span>
          <span class="file-count-circle">
            <span>{{ getDocumentTemplateCountByCategoryId(category.id) }}</span>
          </span>
        </span>
      </mat-list-option>
    </ng-container>
    <ng-template #noCategories>
      <span class="no-categories">No categories were found</span>
    </ng-template>
  </mat-selection-list>
</div>
