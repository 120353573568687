<mat-toolbar>
  <div class="logo">
    <div>
      <img [src]="'./assets/newmark-logo.png'" />
    </div>
    <span>DOCS</span>
  </div>

  <div class="user-info">
    <span>{{ profileDisplayName | default }}</span>
    <ng-container *ngIf="base64ProfileImage; else alternateProfileImage">
      <img [src]="'data:image/png;base64, ' + base64ProfileImage" />
    </ng-container>
    <ng-template #alternateProfileImage>
      <div class="alternate-profile-image">
        <span>{{ profileInitials | default }}</span>
      </div>
    </ng-template>
  </div>
</mat-toolbar>
