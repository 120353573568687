import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared';
import { StringUtilities } from 'src/app/shared/utilities/string-utilities';

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent extends BaseComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly className = 'error-page';

  private _detailedErrorMessages: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public detailedErrorMessages$: Observable<string[]> = this._detailedErrorMessages.asObservable();

  @Input()
  public errorTitle: string;

  @Input()
  set informationalTextLine(value: string) {
    this.informationalTextLines = [value];
  }

  @Input()
  public informationalTextLines: string[] = [];

  @Input()
  set detailedErrorMessage(value: string) {
    this.detailedErrorMessages = [value];
  }

  @Input()
  get detailedErrorMessages(): string[] {
    return this._detailedErrorMessages.getValue();
  }
  set detailedErrorMessages(value: string[]) {
    this._detailedErrorMessages.next(value);
  }

  constructor(private _route: ActivatedRoute) {
    super();
  }

  public ngOnInit(): void {
    this._route.queryParams.pipe(takeUntil(this._destroy)).subscribe((params) => {
      const detailedErrorMessage = params['detailedErrorMessage'];
      if (!StringUtilities.IsNullOrUndefinedOrEmpty(detailedErrorMessage)) {
        const detailedErrorMessages: string[] = this.detailedErrorMessages;
        detailedErrorMessages.push(detailedErrorMessage);
        this._detailedErrorMessages.next(detailedErrorMessages);
      }
    });
  }

  public override ngOnDestroy(): void {
    this._detailedErrorMessages.complete();
    super.ngOnDestroy();
  }
}
