import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'document-explorer-deal-info',
  templateUrl: './document-explorer-deal-info.component.html',
  styleUrls: ['./document-explorer-deal-info.component.scss']
})
export class DocumentExplorerDealInfoComponent {
  @HostBinding('class') readonly className = 'document-explorer-deal-info';

  /** The deal name to display. */
  @Input()
  public dealName: string;

  /** the loan number to display. */
  @Input()
  public loanNumber: string;
}
