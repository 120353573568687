<div mat-dialog-title class="dialog-title">
  <div>
    <span class="title-text">{{
      "Dealpath Missing Fields " + "(" + data?.documentTemplate?.tokensWithoutValues?.length + ")"
    }}</span>
    <span class="document-template-label">{{ data?.documentTemplate?.label }}</span>
  </div>

  <button mat-icon-button aria-label="close dialog" mat-dialog-close (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="dialog-content" mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let token of data?.documentTemplate?.tokensWithoutValues">
      <span>{{ token }}</span>
    </mat-list-item>
  </mat-list>
</div>
