import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { DocumentTemplateSelectionByCategory } from '../../models/document-template-selection-by-category.model';
import { DocumentTemplate } from '../../models/document-template.model';

@Component({
  selector: 'document-explorer-document-template-list',
  templateUrl: './document-explorer-document-template-list.component.html',
  styleUrls: ['./document-explorer-document-template-list.component.scss']
})
export class DocumentExploreDocumentTemplateListComponent implements OnDestroy {
  @HostBinding('class') readonly className = 'document-explorer-document-template-list';

  @Input()
  public categoryName: string;

  @Input()
  public documentTemplates: DocumentTemplate[];

  @Input()
  public disabled: boolean;

  @Input()
  public selectedCategoryId: number | null;

  @Input()
  public documentTemplateSelectionByCategories: DocumentTemplateSelectionByCategory[];

  @Output()
  public onDocumentTemplateWarningClickEvent: EventEmitter<DocumentTemplate> = new EventEmitter<DocumentTemplate>();

  @Output()
  public documentTemplateSelectionByCategoriesChanged: EventEmitter<DocumentTemplate[]> = new EventEmitter<
    DocumentTemplate[]
  >();

  public onDocumentTemplateWarningClick(event: any, documentTemplate: DocumentTemplate): void {
    event.stopPropagation();
    this.onDocumentTemplateWarningClickEvent.emit(documentTemplate);
  }

  public isDocumentTemplateSelected(documentTemplate: DocumentTemplate) {
    const selectionModel = this._getDocumentTemplateSelectionByCategoryId(this.selectedCategoryId);
    return selectionModel.isSelected(documentTemplate);
  }
  public selectedDocumentTemplatesChanged(event: MatSelectionListChange): void {
    return this._selectedDocumentTemplatesChangedByCategoryId(this.selectedCategoryId, event);
  }

  private _selectedDocumentTemplatesChangedByCategoryId(categoryId: number, event: MatSelectionListChange): void {
    const selectionModel = this._getDocumentTemplateSelectionByCategoryId(categoryId);
    const selectedOptions: DocumentTemplate[] = event.options.filter((f) => f.selected).map((m) => m.value);
    const deselectedOptions: DocumentTemplate[] = event.options.filter((f) => !f.selected).map((m) => m.value);

    selectionModel.select(...selectedOptions);

    let selectedDocumentTemplates: DocumentTemplate[] = selectionModel.selected;

    deselectedOptions.forEach((deselectedOption) => {
      selectedDocumentTemplates = selectedDocumentTemplates.filter((f) => f.id !== deselectedOption.id);
    });

    selectionModel.clear();
    selectionModel.select(...selectedDocumentTemplates);
  }

  private _getDocumentTemplateSelectionByCategoryId(categoryId: number): SelectionModel<DocumentTemplate> {
    return this.documentTemplateSelectionByCategories?.filter((f) => f.categoryId == categoryId)[0]
      ?.selectedDocumentTemplates;
  }

  public ngOnDestroy(): void {
    this.onDocumentTemplateWarningClickEvent.complete();
    this.documentTemplateSelectionByCategoriesChanged.complete();
  }
}
