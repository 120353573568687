import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, delay, Observable, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared';
import { SpinnerOverlayComponent } from '../components/spinner-overlay-component/spinner-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService extends BaseComponent implements OnDestroy {
  private _overlayRef: OverlayRef = null;
  private _componentRef: ComponentRef<SpinnerOverlayComponent>;
  public message: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public message$: Observable<string> = this.message.asObservable();

  constructor(private _overlay: Overlay) {
    super();

    this.message$.pipe(takeUntil(this._destroy), delay(300)).subscribe((message: string) => {
      if (this._componentRef) {
        this._componentRef.instance.message = message;
      }
    });
  }

  public show(message: string = ''): void {
    if (!this._overlayRef) {
      this._overlayRef = this._overlay.create();
    }

    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    this._componentRef = this._overlayRef.attach(spinnerOverlayPortal);
    this.message.next(message);
  }

  public hide(): void {
    if (!!this._overlayRef) {
      this._overlayRef.detach();
      this._componentRef = null;
      this.message.next(null);
    }
  }

  public override ngOnDestroy(): void {
    this.message.complete();
    super.ngOnDestroy();
  }
}
