import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  catchError,
  concatMap,
  filter,
  forkJoin,
  fromEvent,
  interval,
  map,
  of,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { StringUtilities } from '../../../shared';
import { ApiFailureResponse, ApiResponse, BaseService } from '../../../shared/base-services/base-service.service';
import { MessageService } from '../../message/services/message.service';
import { SpinnerOverlayService } from '../../spinner-overlay/services/spinner-overlay.service';
import { Category } from '../models/category.model';
import { DealData } from '../models/deal-data.model';
import { DocumentExplorerServiceBaseData } from '../models/document-explorer-service-base-data.model';
import { DocumentTemplate } from '../models/document-template.model';
import { ProfileDataResponse } from '../models/profile-data-response.model';
import { ProfileData } from '../models/profile.data.model';
import { AuthApiService } from './auth-api.service';

/** A service that is used for interacting with the APIs that are used by the document explorer. */
@Injectable({
  providedIn: 'root'
})
export class DocumentExplorerService extends BaseService<DocumentExplorerServiceBaseData> implements OnDestroy {
  private _categories = new BehaviorSubject<Category[]>([]);
  private _isNetworkConnectionOnline = new BehaviorSubject<boolean>(true);
  private _networkOfflineEvent: Subscription;
  private _networkOnlineEvent: Subscription;
  private _isAppUnderMaintenance = new BehaviorSubject<boolean>(false);

  public isNetworkConnectionOnline = this._isNetworkConnectionOnline.asObservable();
  public isAppUnderMaintenance = this._isAppUnderMaintenance.asObservable();
  public dealData = new BehaviorSubject<DealData>({});
  public rawDealData = new BehaviorSubject<any>({});
  public profileData = new BehaviorSubject<ProfileData>(null);

  public categories$ = this._categories.asObservable();
  public dealData$ = this.dealData.asObservable();
  public rawDealData$ = this.rawDealData.asObservable();
  public profileData$ = this.profileData.asObservable();

  get categories(): Category[] {
    return this._categories.getValue();
  }

  public constructor(
    _http: HttpClient,
    _messageService: MessageService,
    private _authApiService: AuthApiService,
    private _spinnerOverlayService: SpinnerOverlayService,
    private _router: Router
  ) {
    super(_http, _messageService, environment.nDocsApiRoute);

    this._networkOfflineEvent = fromEvent(window, 'offline').subscribe((s) => {
      this._isNetworkConnectionOnline.next(false);
      this._messageService.showMessage({
        content: 'The network connection has gone offline.',
        isError: true,
        disableTimeout: true
      });
    });

    this._networkOnlineEvent = fromEvent(window, 'online').subscribe((s) => {
      this._isNetworkConnectionOnline.next(true);
      this._messageService.showMessage({
        title: '',
        content: 'The network connection is back online.'
      });
    });

    /** An interval that polls an endpoint to determine if the the app is under maintenance and redirects the user to a maintenance screen if it is. */
    interval((environment?.underMaintenanceCheckIntervalSeconds ?? 60) * 1000)
      .pipe(
        takeUntil(this._destroy),
        filter(() => this.baseDataLoaded && this._isNetworkConnectionOnline.getValue()),
        switchMap(() => this._getIsAppUnderMaintenance())
      )
      .subscribe((isAppUnderMaintenance: boolean) => {
        if (isAppUnderMaintenance) {
          this._onUnderMaintenance();
        }
      });
  }

  /** Loads the data needed by the document explorer for a given deal id. */
  public getBaseDataWithId(dealId: number): Observable<DocumentExplorerServiceBaseData> {
    return this.isNetworkConnectionOnline.pipe(
      filter((isNetworkConnectionOnline) => isNetworkConnectionOnline),
      concatMap(() =>
        this._getIsAppUnderMaintenance().pipe(
          tap((isAppUnderMaintenance) => {
            if (isAppUnderMaintenance) {
              this._onUnderMaintenance();
            }
          })
        )
      ),
      concatMap(() => {
        return this._getProfileData(dealId);
      }),
      concatMap((baseData: DocumentExplorerServiceBaseData) => {
        return forkJoin([of(baseData), this._getUserGroups(baseData.userId), this._getDealData(baseData.dealId)]);
      }),
      concatMap(([baseData]) => {
        return forkJoin([of(baseData), this._getDocumentData(baseData.userId, this.rawDealData.getValue())]);
      }),
      map(([response]) => {
        this._setupBaseData(response);
        return response;
      })
    );
  }

  /** Retrieves a boolean value for if the application is under maintenance. */
  private _getIsAppUnderMaintenance(): Observable<boolean> {
    if (environment?.forceUnderMaintenance) {
      this._isAppUnderMaintenance.next(true);
      return of(true);
    }

    return this._http
      .get(`${environment.nDocsApiRoute}/ndocs/app-status`, {
        headers: { 'Ocp-Apim-Subscription-Key': environment.nDocsOcpApimSubscriptionKey },
        responseType: 'text'
      })
      .pipe(
        map((response: string) => {
          this._isAppUnderMaintenance.next(false);
          return false;
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.status === 503 && response.error === 'OFFLINE') {
            this._isAppUnderMaintenance.next(true);
            return of(true);
          } else {
            this._isAppUnderMaintenance.next(false);
            return of(false);
          }
        })
      );
  }

  /** Navigate to the under maintenance page. */
  private _onUnderMaintenance(): void {
    this._router.navigate(['under-maintenance'], {
      replaceUrl: true
    });
  }

  /** Navigate to the load failed page. */
  private _onLoadError(detailedErrorMessage: string): void {
    this._router.navigate(['load-failed'], {
      replaceUrl: true,
      queryParams: { detailedErrorMessage: detailedErrorMessage }
    });
  }

  private _getProfileData(dealId: number): Observable<DocumentExplorerServiceBaseData> {
    const defaultFailureMessage = 'Failed to load basic information.';

    this._spinnerOverlayService.message.next('Loading basic information');

    return this._authApiService.getProfileData().pipe(
      map((response: ApiResponse<ProfileDataResponse>) => {
        let baseData: DocumentExplorerServiceBaseData = null;

        if (response.isOk) {
          baseData = {
            dealId: dealId,
            userId: response.data.clientPrincipal.userDetails,
            loadErrors: []
          } as DocumentExplorerServiceBaseData;
          return baseData;
        } else {
          throw new Error(defaultFailureMessage);
        }
      }),
      catchError((error) => {
        this._onLoadError(error?.message || defaultFailureMessage);
        return EMPTY;
      })
    );
  }

  private _getUserGroups(userId: string): Observable<ApiResponse<any>> {
    const defaultFailureMessage = `Failed to load the user data for user id '${userId}'.`;

    this._spinnerOverlayService.message.next('Loading user data');

    if (isDevMode() && !StringUtilities.IsNullOrUndefinedOrEmpty(environment?.overrideUserId)) {
      userId = environment.overrideUserId;
    }

    return this._get(`util/usrgrps?ss=1&photo=true&uid=${userId}`, {
      headers: { 'Ocp-Apim-Subscription-Key': environment.utilOcpApimSubscriptionKey },
      hideFailureMessage: true,
      defaultFailureMessage: defaultFailureMessage
    }).pipe(
      switchMap((response: ApiResponse<any>) => {
        //The API returns a list of errors on a 200 result so it needs to be parsed for any errors.
        if (response.isOk()) {
          if (response.data.Error) {
            const errorMessage = defaultFailureMessage;
            response.failure = { message: errorMessage } as ApiFailureResponse;
            throw new Error(errorMessage);
          }
        } else {
          throw new Error(defaultFailureMessage);
        }

        return of(response);
      }),
      tap((response) => {
        if (response.isOk()) {
          const profileData: ProfileData = {};
          let firstName: string = response.data?.UserInfo?.givenName;
          let lastName: string = response.data?.UserInfo?.surname;
          let fullName: string = '';
          let initials: string = '';

          if (firstName || lastName) {
            if (firstName) {
              fullName += firstName;
              initials += firstName?.charAt(0)?.toUpperCase();
            }

            if (lastName) {
              fullName += fullName ? ` ${lastName}` : lastName;
              initials += lastName?.charAt(0)?.toUpperCase();
            }
          }

          profileData.profileDisplayName = StringUtilities.IsNullOrUndefinedOrEmpty(fullName) ? null : fullName;
          profileData.profileInitials = StringUtilities.IsNullOrUndefinedOrEmpty(initials) ? null : initials;

          if (response.data?.Photo && response.data?.Photo['$content']) {
            profileData.base64ProfileImage = response.data?.Photo['$content'];
          }

          this.profileData.next(profileData);
        }
      }),
      catchError((error) => {
        this._onLoadError(error?.message || defaultFailureMessage);
        return EMPTY;
      })
    );
  }

  private _getDealData(dealId: number): Observable<ApiResponse<any>> {
    const defaultFailureMessage = `Failed to load the deal data for deal id '${dealId.toString()}'.`;

    this._spinnerOverlayService.message.next('Loading deal information. This may take a minute or two.');

    return this._get(`nDocs/get-dealpath-deal?ss=1&id=${dealId.toString()}`, {
      headers: { 'Ocp-Apim-Subscription-Key': environment.nDocsOcpApimSubscriptionKey },
      hideFailureMessage: true,
      defaultFailureMessage: defaultFailureMessage
    }).pipe(
      tap((response: ApiResponse<any>) => {
        if (response.isOk()) {
          const loanNumber = response.data['loan.LoanNumber'];
          const dealName = response.data?.DealName;
          const dealData: DealData = {
            dealName: StringUtilities.IsNullOrUndefinedOrEmpty(dealName) ? null : dealName,
            loanNumber: StringUtilities.IsNullOrUndefinedOrEmpty(loanNumber) ? null : loanNumber
          };
          this.rawDealData.next(response.data);
          this.dealData.next(dealData);
        } else {
          throw new Error(defaultFailureMessage);
        }
      }),
      catchError((error) => {
        this._onLoadError(error?.message || defaultFailureMessage);
        return EMPTY;
      })
    );
  }

  private _getDocumentData(userId: string, rawDealData: any): Observable<ApiResponse<any>> {
    const defaultFailureMessage = `Failed to load and parse the document data for user id '${userId}'.`;

    this._spinnerOverlayService.message.next('Loading documents');

    if (isDevMode() && !StringUtilities.IsNullOrUndefinedOrEmpty(environment?.overrideUserId)) {
      userId = environment.overrideUserId;
    }

    return this._get(`nDocs/get-doc-fields?ss=1&f=1&uid=${userId}`, {
      headers: { 'Ocp-Apim-Subscription-Key': environment.nDocsOcpApimSubscriptionKey },
      hideFailureMessage: true,
      defaultFailureMessage: defaultFailureMessage
    }).pipe(
      tap((response: ApiResponse<any>) => {
        if (response.isOk()) {
          let categories: Category[] = [];

          let labelSortedCategories: Category[] = [];
          let hasOtherCategory: boolean = false;
          let nonLabelSortedCategories: Category[] = [];
          // Build the categories.
          response.data?.forEach((responseItem: any) => {
            const categoryId: number = Number(responseItem['Category']?.Id);
            const categoryLabel = responseItem['Category']?.Value;
            
            if (isNaN(categoryId)) {
              if (categories.filter((f) => f.id === null)?.length === 0) {
                hasOtherCategory = true;
              }
            } else {
              const isLabelSortedCategory: boolean =
                categoryLabel.substring(3, 4) === '.' && !isNaN(parseInt(categoryLabel.substring(0, 3)));

              if (isLabelSortedCategory) {
                if (labelSortedCategories.filter((f) => f.id === categoryId)?.length === 0) {
                  labelSortedCategories.push({
                    id: categoryId,
                    sortLabel: categoryLabel,
                    label: categoryLabel.substring(4, categoryLabel.length),
                    documentTemplates: []
                  } as Category);
                }
              } else {
                if (nonLabelSortedCategories.filter((f) => f.id === categoryId)?.length === 0) {
                  nonLabelSortedCategories.push({
                    id: categoryId,
                    sortLabel: categoryLabel,
                    label: categoryLabel,
                    documentTemplates: []
                  } as Category);
                }
              }
            }
          });

          labelSortedCategories = labelSortedCategories.sort((a, b) => a.sortLabel.localeCompare(b.sortLabel));
          nonLabelSortedCategories = nonLabelSortedCategories.sort((a, b) => a.sortLabel.localeCompare(b.sortLabel));

          categories = categories.concat(labelSortedCategories);

          if (hasOtherCategory) {
            categories = categories.concat({ id: null, label: 'Other', documentTemplates: [] } as Category);
          }

          categories = categories.concat(nonLabelSortedCategories);

          // Add the document templates to the applicable category.
          response.data?.forEach((responseItem: any) => {
            const fileNameWithExtension = responseItem['{FilenameWithExtension}'];
            const documentTemplateId: number = Number(responseItem['ID']);
            const categoryId: number = Number(responseItem['Category']?.Id);
            const tokensWithoutValues: string[] = this._getTokensWithoutValues(responseItem.Tokens, rawDealData);
            const iterator = responseItem['Iterator'];
            if (isNaN(categoryId)) {
              const selectedOtherCategory = categories.filter((f) => f.id === null)[0];
              selectedOtherCategory.documentTemplates.push({
                id: documentTemplateId,
                label: fileNameWithExtension,
                tokensWithoutValues: tokensWithoutValues,
                iterator: iterator
              } as DocumentTemplate);
            } else {
              const selectedCategory = categories.filter((f) => f.id === categoryId)[0];

              if (selectedCategory) {
                selectedCategory.documentTemplates.push({
                  id: documentTemplateId,
                  label: fileNameWithExtension,
                  tokensWithoutValues: tokensWithoutValues,
                  iterator: iterator
                } as DocumentTemplate);
              }
            }
          });

          this._categories.next(categories);
        } else {
          throw new Error(defaultFailureMessage);
        }
      }),
      catchError((error) => {
        this._onLoadError(error?.message || defaultFailureMessage);
        return EMPTY;
      })
    );
  }

  /** Get tokens in the document template that have no values. */
  private _getTokensWithoutValues(tokens: any, rawDealData: any): string[] {
    this._spinnerOverlayService.message.next('Parsing documents');
    const tokensWithoutValues: string[] = [];
    let atLeastOneValueFound = false;
    let fieldFound = false;

    if (tokens) {
      const tokensJSON = JSON.parse(tokens);

      for (let token in tokensJSON) {
        if (typeof rawDealData[token] != 'object' && typeof rawDealData[token] != 'undefined') {
          if (rawDealData[token] == '') tokensWithoutValues.push(token);
        } else {
          const fldObj = rawDealData;
          for (let childAttr in fldObj) {
            if (Array.isArray(fldObj[childAttr])) {
              fldObj[childAttr].forEach((o: any) => {
                if (o[token] != '') {
                  atLeastOneValueFound = true;
                }
                if (o[token] == '') fieldFound = true;
              });
            }
          }
          if (fieldFound && !atLeastOneValueFound) {
            tokensWithoutValues.push(token);
          }
        }
      }
    }

    return tokensWithoutValues;
  }

  /** Gets recently generated documents. */
  public getRecentlyGeneratedDocuments(userId: string, dealId: number): Observable<ApiResponse<any>> {
    return this._get(`ndocs/checkstatus?s=1&uid=${userId}&dealid=${dealId}`, {
      headers: { 'Ocp-Apim-Subscription-Key': environment.nDocsOcpApimSubscriptionKey },
      defaultFailureMessage: 'Failed to load the recently generated documents.'
    });
  }

  /** Checks the document generation status. */
  public checkDocumentGenerationStatus(downloadSessionId: string): Observable<ApiResponse<any>> {
    return this._get(`ndocs/checkstatus?s=1&id=${downloadSessionId}`, {
      headers: { 'Ocp-Apim-Subscription-Key': environment.nDocsOcpApimSubscriptionKey },
      defaultFailureMessage: 'Failed to check the document generation status.'
    });
  }

  public generateDocuments(
    documentTemplates: DocumentTemplate[],
    dealId: number,
    userId: string,
    rawDealData: any
  ): Observable<ApiResponse<string>> {
    const documentTemplatesList = documentTemplates.map((documentTemplate) => documentTemplate.label).join('|');
    const documentTemplatesExtProp = documentTemplates.map((documentTemplate) => documentTemplate.label + '!' + documentTemplate.iterator).join('|');
console.log(documentTemplates);
console.log(rawDealData);
    const body = {
      DealID: dealId,
      FilesList: documentTemplatesList,
      User: userId,
      JSON: rawDealData,
      ExtProp:documentTemplatesExtProp
    };
    return this._post<string>('nDocs/bulkdownload', body, {
      headers: { 'Ocp-Apim-Subscription-Key': environment.nDocsOcpApimSubscriptionKey },
      defaultFailureMessage: 'Failed to request the generation of the the selected documents.',
      responseType: 'text'
    });
  }

  public override ngOnDestroy(): void {
    this._categories.complete();
    this.dealData.complete();
    this.rawDealData.complete();
    this.profileData.complete();
    this._isNetworkConnectionOnline.complete();
    this._isAppUnderMaintenance.complete();
    this._networkOfflineEvent.unsubscribe();
    this._networkOnlineEvent.unsubscribe();
    super.ngOnDestroy();
  }
}
