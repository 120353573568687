import { Component, HostBinding, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentTemplate } from '../../models/document-template.model';

export interface DocumentTemplateWarningDialogData {
  /** The document template to show warnings for. */
  documentTemplate: DocumentTemplate;
}

@Component({
  selector: 'document-template-warning-dialog',
  templateUrl: 'document-template-warning-dialog.component.html',
  styleUrls: ['document-template-warning-dialog.component.scss']
})
export class DocumentTemplateWarningDialogComponent {
  @HostBinding('class') readonly className = 'document-template-warning-dialog';

  constructor(
    public dialogRef: MatDialogRef<DocumentTemplateWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentTemplateWarningDialogData
  ) {}

  public onClose(): void {
    this.dialogRef.close();
  }
}
