import { Component, isDevMode, OnInit } from '@angular/core';
import {
  ChildrenOutletContexts,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { filter } from 'rxjs';
import { SpinnerOverlayService } from './modules/spinner-overlay/services/spinner-overlay.service';
import { BaseComponent, fadeInOutRouteAnimation } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInOutRouteAnimation]
})
export class AppComponent extends BaseComponent implements OnInit {
  public isLoading: boolean = false;

  constructor(
    _spinnerOverlayService: SpinnerOverlayService,
    _router: Router,
    private _contexts: ChildrenOutletContexts
  ) {
    super();
    _router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          _spinnerOverlayService.show('Loading');
        } else {
          _spinnerOverlayService.hide();
        }
      });
  }

  public ngOnInit(): void {
    if (isDevMode()) {
      console.log('Application is in development mode.');
    }
  }

  public getRouteAnimationData(): any {
    return this._contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
