<div class="document-templates-container">
  <div class="category-label">
    <span>
      {{ categoryName ?? "Select a category" }}
    </span>
  </div>

  <ng-container *ngIf="documentTemplates?.length > 0; else noDocumentTemplates">
    <mat-selection-list [multiple]="true" (selectionChange)="selectedDocumentTemplatesChanged($event)">
      <mat-list-option
        color="primary"
        *ngFor="let documentTemplate of documentTemplates"
        [selected]="isDocumentTemplateSelected(documentTemplate)"
        [value]="documentTemplate"
        [disabled]="disabled"
      >
        <div class="document-template">
          <div class="document-template-label">
            <span>{{ documentTemplate.label }}</span>
          </div>

          <div *ngIf="documentTemplate?.tokensWithoutValues?.length > 0" class="document-template-warning">
            <button
              mat-icon-button
              aria-label="warning"
              (keydown.enter)="onDocumentTemplateWarningClick($event, documentTemplate)"
              (click)="onDocumentTemplateWarningClick($event, documentTemplate)"
              matTooltip="There are fields missing in Dealpath impacting values in this document."
              [disabled]="disabled"
            >
              <mat-icon [matBadge]="documentTemplate?.tokensWithoutValues?.length" matBadgeColor="primary"
                >warning</mat-icon
              >
            </button>
          </div>
        </div>
      </mat-list-option>
    </mat-selection-list>
  </ng-container>
  <ng-template #noDocumentTemplates>
    <span class="no-document-templates">No documents were found</span>
  </ng-template>
</div>
