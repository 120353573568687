import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { OverviewMode } from '../../models/document-explorer-overview-mode.enum';

@Component({
  selector: 'document-explorer-overview-action-buttons',
  templateUrl: './document-explorer-overview-action-buttons.component.html',
  styleUrls: ['./document-explorer-overview-action-buttons.component.scss']
})
export class DocumentExplorerOverviewActionButtons implements OnDestroy {
  @HostBinding('class') readonly className = 'document-explorer-overview-action-buttons';

  public readonly documentTemplateSelectionEnum = OverviewMode.DocumentTemplateSelection;
  public readonly generatedDocumentsEnum = OverviewMode.GeneratedDocuments;
  public readonly generatedDocumentsHistoryEnum = OverviewMode.GeneratedDocumentsHistory;

  @Input()
  public overviewMode: OverviewMode;

  @Input()
  public documentGenerationHistoryButtonsDisabled: boolean;

  @Input()
  public documentGenerationButtonDisabled: boolean;

  @Input()
  public documentGenerationResetButtonDisabled: boolean;

  @Output()
  public onGenerateDocumentsButtonEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onDocumentGenerationResetEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onShowDocumentGenerationHistoryEvent: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onCloseDocumentGenerationHistoryEvent: EventEmitter<void> = new EventEmitter<void>();

  public onGenerateDocumentsButtonClick(): void {
    this.onGenerateDocumentsButtonEvent.emit();
  }

  public onDocumentGenerationResetClick(): void {
    this.onDocumentGenerationResetEvent.emit();
  }

  public onShowDocumentGenerationHistoryClick(): void {
    this.onShowDocumentGenerationHistoryEvent.emit();
  }

  public onCloseDocumentGenerationHistoryClick(): void {
    this.onCloseDocumentGenerationHistoryEvent.emit();
  }

  public ngOnDestroy(): void {
    this.onGenerateDocumentsButtonEvent.complete();
    this.onDocumentGenerationResetEvent.complete();
    this.onShowDocumentGenerationHistoryEvent.complete();
    this.onCloseDocumentGenerationHistoryEvent.complete();
  }
}
