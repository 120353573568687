import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';
import { BehaviorSubject, catchError, filter, interval, map, switchMap, take, takeUntil } from 'rxjs';
import { DocumentExplorerService } from 'src/app/modules/document-explorer/services/document-explorer.service';
import { MessageService } from 'src/app/modules/message/services/message.service';
import { BaseComponentWithIsProcessing, StringUtilities, fadeInAnimation, fadeInOutAnimation } from 'src/app/shared';
import { Category } from '../../models/category.model';
import { OverviewMode } from '../../models/document-explorer-overview-mode.enum';
import { DocumentTemplateSelectionByCategory } from '../../models/document-template-selection-by-category.model';
import { DocumentTemplate } from '../../models/document-template.model';
import { GeneratedDocument } from '../../models/generated-document.model';
import { HistoricalGeneratedDocumentGroup } from '../../models/historical-generated-document-group.model';
import { MappedGeneratedDocumentResponse } from '../../models/mapped-generated-document-response.model';
import {
  DocumentTemplateWarningDialogComponent,
  DocumentTemplateWarningDialogData
} from '../document-template-warning-dialog/document-template-warning-dialog.component';

@Component({
  selector: 'document-explorer',
  templateUrl: './document-explorer.component.html',
  styleUrls: ['./document-explorer.component.scss'],
  animations: [fadeInOutAnimation, fadeInAnimation]
})
export class DocumentExplorerComponent extends BaseComponentWithIsProcessing implements OnInit, OnDestroy {
  @HostBinding('class') readonly className = 'document-explorer';

  private readonly _selectedCategoryId = new BehaviorSubject<number>(null);
  private readonly _downloadId = new BehaviorSubject<string>(null);
  private readonly _generatedDocuments = new BehaviorSubject<GeneratedDocument[]>(null);
  private readonly _generatingDocumentsMessage = new BehaviorSubject<string>(null);
  private readonly _overviewMode = new BehaviorSubject<OverviewMode>(OverviewMode.DocumentTemplateSelection);
  private readonly _historicalGeneratedDocumentGroups = new BehaviorSubject<HistoricalGeneratedDocumentGroup[]>([]);

  private _previousOverviewMode?: OverviewMode = null;

  public readonly selectedCategoryId$ = this._selectedCategoryId.asObservable();
  public readonly downloadId$ = this._downloadId.asObservable();
  public readonly generatedDocuments$ = this._generatedDocuments.asObservable();
  public readonly generatingDocumentsMessage$ = this._generatingDocumentsMessage.asObservable();
  public readonly overviewMode$ = this._overviewMode.asObservable();
  public readonly historicalGeneratedDocumentGroups$ = this._historicalGeneratedDocumentGroups.asObservable();

  public readonly documentTemplateSelectionEnum = OverviewMode.DocumentTemplateSelection;
  public readonly generatedDocumentsEnum = OverviewMode.GeneratedDocuments;
  public readonly generatedDocumentsHistoryEnum = OverviewMode.GeneratedDocumentsHistory;

  public documentTemplateSelectionByCategories: DocumentTemplateSelectionByCategory[] = [];
  public documentTemplates: DocumentTemplate[] = [];

  get hasDocumentsSelected(): boolean {
    return this.getTotalDocumentsSelectedCount() > 0;
  }

  constructor(
    public documentExplorerService: DocumentExplorerService,
    private _dialog: MatDialog,
    private _messageService: MessageService
  ) {
    super([documentExplorerService.isProcessing$]);
    this._resetDocumentTemplateSelectionByCategories();
  }

  public ngOnInit(): void {
    // Runs when the selectedCategoryId changes.
    this.selectedCategoryId$
      .pipe(
        takeUntil(this._destroy),
        map((selectedCategoryId: number) => {
          return this.documentExplorerService.categories.filter((category) => category.id === selectedCategoryId)[0]
            ?.documentTemplates;
        })
      )
      .subscribe((documentTemplates: DocumentTemplate[]) => {
        if (documentTemplates) {
          this.documentTemplates = documentTemplates;
        }
      });

    // Interval that polls the server while a downloadId is set and until the document are generated.
    // After the documents are generated downloadId will be set back to null to stop the polling.
    interval(1000)
      .pipe(
        takeUntil(this._destroy),
        switchMap((_) => this.downloadId$),
        filter((downloadId: string) => !StringUtilities.IsNullOrUndefinedOrEmpty(downloadId)),
        switchMap((downloadId) => this.documentExplorerService.checkDocumentGenerationStatus(downloadId)),
        map((response) => {
          if (response.isOk()) {
            return this._getMappedGeneratedDocumentResponse(response.data[0]);
          } else {
            this._messageService.showMessage({
              content: 'Failed to check the document generation status.',
              isError: true
            });
            return null;
          }
        })
      )
      .subscribe((mappedGeneratedDocumentResponse: MappedGeneratedDocumentResponse) => {
        if (mappedGeneratedDocumentResponse) {
          this._generatingDocumentsMessage.next(
            `Processing ${mappedGeneratedDocumentResponse.documentsDownloaded?.length} out of ${mappedGeneratedDocumentResponse.documentsRequested?.length} documents`
          );

          if (
            mappedGeneratedDocumentResponse.documentsDownloaded.length ===
            mappedGeneratedDocumentResponse.documentsRequested.length
          ) {
            const generatedDocuments: GeneratedDocument[] = this._buildGenerateDocumentsList(
              mappedGeneratedDocumentResponse.documentsDownloaded,
              mappedGeneratedDocumentResponse.documentsRequested,
              mappedGeneratedDocumentResponse.downloadUrl,
              this._downloadId.getValue()
            );
            this._generatedDocuments.next(generatedDocuments);

            this._downloadId.next(null);
            this._generatingDocumentsMessage.next(null);
          }
        }
      });

    if (this.documentExplorerService?.categories?.length > 0) {
      this._selectedCategoryId.next(this.documentExplorerService.categories[0].id);
    }
  }

  public override ngOnDestroy(): void {
    this._selectedCategoryId.complete();
    this._downloadId.complete();
    this._generatedDocuments.complete();
    this._generatingDocumentsMessage.complete();
    this._overviewMode.complete();
    this._historicalGeneratedDocumentGroups.complete();
    super.ngOnDestroy();
  }

  public selectedCategoryIdChanged(categoryId: any): void {
    this._selectedCategoryId.next(categoryId);
  }

  /** Manages the state of the currently selected document templates belonging to a given category when the selection is changed on the UI. */
  public selectedDocumentTemplatesChangedByCategoryId(categoryId: number, event: MatSelectionListChange): void {
    const selectionModel = this.getDocumentTemplateSelectionByCategoryId(categoryId);

    const selectedOptions: DocumentTemplate[] = event.options.filter((f) => f.selected).map((m) => m.value);
    const deselectedOptions: DocumentTemplate[] = event.options.filter((f) => !f.selected).map((m) => m.value);

    selectionModel.select(...selectedOptions);

    let selectedDocumentTemplates: DocumentTemplate[] = selectionModel.selected;

    deselectedOptions.forEach((deselectedOption) => {
      selectedDocumentTemplates = selectedDocumentTemplates.filter((f) => f.id !== deselectedOption.id);
    });

    selectionModel.clear();
    selectionModel.select(...selectedDocumentTemplates);
  }

  /** Get the name of a category given the provided categoryId. */
  public getSelectedCategoryName(categoryId: number): string {
    return this.documentExplorerService.categories.filter((f) => f.id == categoryId)[0]?.label;
  }

  /** Determines if a document template for a given category is currently selected or not. */
  public isDocumentTemplateSelectedByCategory(categoryId: number, documentTemplates: DocumentTemplate) {
    const selectionModel = this.getDocumentTemplateSelectionByCategoryId(categoryId);

    return selectionModel.isSelected(documentTemplates);
  }

  /** Gets the selection model that contains the selected document templates for a given category. */
  public getDocumentTemplateSelectionByCategoryId(categoryId: number): SelectionModel<DocumentTemplate> {
    return this.documentTemplateSelectionByCategories?.filter((f) => f.categoryId == categoryId)[0]
      ?.selectedDocumentTemplates;
  }

  /** Gets the DocumentTemplateSelectionByCategory objects which have document templates selected. */
  public getDocumentTemplateSelectionByCategoriesForDownloadDisplay(): DocumentTemplateSelectionByCategory[] {
    return this.documentTemplateSelectionByCategories.filter((f) => f.selectedDocumentTemplates.selected?.length > 0);
  }

  public getTotalDocumentsSelectedCount(): number {
    let count: number = 0;
    this.documentTemplateSelectionByCategories.forEach((f) => {
      count += f.selectedDocumentTemplates.selected?.length;
    });

    return count;
  }

  public resetDocumentTemplateSelection(): void {
    this._resetDocumentTemplateSelectionByCategories();
  }

  /** Send a request to generate documents from the selected document templates.
   * A polling interval will be signaled to run and retrieve the generated document information till complete.
   */
  public generateDocuments(): void {
    this._overviewMode.next(OverviewMode.GeneratedDocuments);
    const selectedDocumentTemplates: DocumentTemplate[] = [];

    this.documentTemplateSelectionByCategories.forEach((documentTemplateSelectionCategory) => {
      documentTemplateSelectionCategory?.selectedDocumentTemplates?.selected.forEach((documentTemplate) => {
        selectedDocumentTemplates.push(documentTemplate);
      });
    });

    this.documentExplorerService
      .generateDocuments(
        selectedDocumentTemplates,
        this.documentExplorerService.baseData.dealId,
        this.documentExplorerService.baseData.userId,
        this.documentExplorerService.rawDealData.getValue()
      )
      .pipe(
        take(1),
        catchError((error, caught) => {
          this._overviewMode.next(OverviewMode.DocumentTemplateSelection);
          return caught;
        })
      )
      .subscribe((response) => {
        if (response.isOk()) {
          this._downloadId.next(response.data);
        }
      });
  }

  /** Reset the generated documents on the overview and returns back to the document template selection mode. */
  public resetGeneratedDocumentSelection(): void {
    this._overviewMode.next(OverviewMode.DocumentTemplateSelection);
    this._generatedDocuments.next(null);
    this._resetDocumentTemplateSelectionByCategories();
  }

  /** Sorts the provided document templates and returns them. */
  public sortSelectedDocumentTemplates(documentTemplates: DocumentTemplate[]): DocumentTemplate[] {
    return documentTemplates.sort((a, b) => a.label.localeCompare(b.label));
  }

  /** Opens a dialog to show the list of tokens with missing values on the document template. */
  public onDocumentTemplateWarningClick(documentTemplate: DocumentTemplate): void {
    const data: DocumentTemplateWarningDialogData = {
      documentTemplate: documentTemplate
    };

    this._dialog.open(DocumentTemplateWarningDialogComponent, {
      width: '480px',
      height: '480px',
      minWidth: '480px',
      minHeight: '480px',
      data: data
    });
  }

  /** Shows the generated document history on the overview. */
  public showDocumentGenerationHistory(): void {
    this._previousOverviewMode = this._overviewMode.getValue();
    this._overviewMode.next(OverviewMode.GeneratedDocumentsHistory);
    this.documentExplorerService
      .getRecentlyGeneratedDocuments(
        this.documentExplorerService.baseData.userId,
        this.documentExplorerService.baseData.dealId
      )
      .pipe(
        take(1),
        map((response) => {
          if (response.isOk()) {
            const mappedDocumentGenerationResponseData: MappedGeneratedDocumentResponse[] = [];

            response.data?.forEach((responseItem: any) => {
              const mappedGeneratedDocumentResponse: MappedGeneratedDocumentResponse =
                this._getMappedGeneratedDocumentResponse(responseItem, true);
              mappedDocumentGenerationResponseData.push(mappedGeneratedDocumentResponse);
            });

            return mappedDocumentGenerationResponseData;
          } else {
            this._messageService.showMessage({
              content: 'Failed to load the recently generated documents.',
              isError: true
            });
            return null;
          }
        })
      )
      .subscribe((mappedGeneratedDocumentResponses: MappedGeneratedDocumentResponse[]) => {
        if (mappedGeneratedDocumentResponses) {
          const historicalGeneratedDocumentGroups: HistoricalGeneratedDocumentGroup[] = [];

          mappedGeneratedDocumentResponses?.forEach(
            (mappedGeneratedDocumentResponse: MappedGeneratedDocumentResponse) => {
              const generatedDocuments: GeneratedDocument[] = this._buildGenerateDocumentsList(
                mappedGeneratedDocumentResponse.documentsDownloaded,
                mappedGeneratedDocumentResponse.documentsRequested,
                mappedGeneratedDocumentResponse.downloadUrl,
                mappedGeneratedDocumentResponse.downloadId
              );

              const dateUtc: Date = new Date(mappedGeneratedDocumentResponse.created + 'UTC');
              const historicalGeneratedDocumentGroup: HistoricalGeneratedDocumentGroup = {
                dateUtc: dateUtc,
                generatedDocuments: generatedDocuments
              };

              historicalGeneratedDocumentGroups.push(historicalGeneratedDocumentGroup);
            }
          );

          const sortedHistoricalGeneratedDocumentGroups = historicalGeneratedDocumentGroups.sort(
            (a, b) => b.dateUtc.getTime() - a.dateUtc.getTime()
          );
          this._historicalGeneratedDocumentGroups.next(sortedHistoricalGeneratedDocumentGroups);
        }
      });
  }

  /** Closes the generated document history in the overview and returns back to the previous overview mode. */
  public closeDocumentGenerationHistory(): void {
    this._overviewMode.next(this._previousOverviewMode);
    this._previousOverviewMode = null;
  }

  /** Gets a generated document that matches a requested document template. */
  public getGeneratedDocumentUrlByDocumentTemplate(documentTemplate: DocumentTemplate): GeneratedDocument {
    return this._generatedDocuments
      .getValue()
      .filter((generatedFileUrl) => generatedFileUrl.label === documentTemplate.label)[0];
  }

  /** Takes the response of the call to get recently generated documents and maps it into the intermediate model MappedGeneratedDocumentResponse. */
  private _getMappedGeneratedDocumentResponse(
    response: any,
    isGettingHistoricalData: boolean = false
  ): MappedGeneratedDocumentResponse {
    const created: Date = new Date(response?.Created);
    const downloadUrl: string = isGettingHistoricalData ? response?.DownloadUrl : response?.DownloadURL;

    const filesDownloadedResponse: string = response?.FilesDownloaded;
    const filesRequestedResponse: string = response?.FilesRequested;
    const downloadId: string = response?.ID;

    const documentsDownloaded: string[] = StringUtilities.IsNullOrUndefinedOrEmpty(filesDownloadedResponse)
      ? []
      : filesDownloadedResponse?.split('|').sort((a, b) => a.localeCompare(b));

    const documentsRequested: string[] = StringUtilities.IsNullOrUndefinedOrEmpty(filesRequestedResponse)
      ? []
      : filesRequestedResponse?.split('|').sort((a, b) => a.localeCompare(b));

    const mappedGeneratedDocumentResponse: MappedGeneratedDocumentResponse = {
      created: created,
      downloadUrl: downloadUrl,
      documentsDownloaded: documentsDownloaded,
      documentsRequested: documentsRequested,
      downloadId: downloadId
    };

    return mappedGeneratedDocumentResponse;
  }

  /** Builds a list of generated documents that has been formatted and sorted for use on the UI. */
  private _buildGenerateDocumentsList(
    documentsDownloaded: string[],
    documentRequested: string[],
    downloadUrl: string,
    downloadId: string
  ): GeneratedDocument[] {
    const generatedDocuments: GeneratedDocument[] = [];

    documentsDownloaded
      .sort((a, b) => a.localeCompare(b))
      .filter((f) => documentRequested.indexOf(f) > -1)
      .forEach((documentDownloaded) => {
        const generatedDocument: GeneratedDocument = {
          label: documentDownloaded,
          url: `${downloadUrl}${downloadId}/${documentDownloaded}`,
          hasError: false
        };

        generatedDocuments.push(generatedDocument);
      });

    documentsDownloaded
      .sort((a, b) => a.localeCompare(b))
      .filter((f) => documentRequested.indexOf(f) === -1)
      .forEach((documentDownloaded) => {
        // Strip out 'Error ' text on the returned label of failed documents.
        const label = documentDownloaded.startsWith('Error: ')
          ? documentDownloaded.substring(7, documentDownloaded.length)
          : documentDownloaded;

        const generatedDocument: GeneratedDocument = {
          label: label,
          url: null,
          hasError: true
        };

        generatedDocuments.push(generatedDocument);
      });

    return generatedDocuments.sort((a, b) => a.label.localeCompare(b.label));
  }

  /** Reset the model used for the view state of the UI. */
  private _resetDocumentTemplateSelectionByCategories(): void {
    this.documentTemplateSelectionByCategories = [];
    this.documentExplorerService.categories.forEach((category: Category) => {

      const documentTemplateSelectionByCategory: DocumentTemplateSelectionByCategory = {
        categoryId: category.id,
        selectedDocumentTemplates: new SelectionModel<DocumentTemplate>(true, [], true, (o1, o2) => o1.id === o2.id),
        panelOpenState: true
      };

      this.documentTemplateSelectionByCategories.push(documentTemplateSelectionByCategory);
    });
  }
}
