import { animate, group, query, style, transition, trigger } from '@angular/animations';

const fadeInTransition = transition(':enter', [
  style({ opacity: '0' }),
  animate('250ms ease-in-out', style({ opacity: '1' }))
]);

const fadeInOutTransition = transition(':leave', [
  style({ opacity: '1' }),
  animate('250ms ease-in-out', style({ opacity: '0' }))
]);

export const fadeInOutRouteAnimation = trigger('routeAnimations', [
  transition('* <=> *', [
    query(':enter, :leave', style({}), { optional: true }),
    group([
      query(':enter', [style({ opacity: '0' }), animate('250ms ease-in-out', style({ opacity: '1' }))], {
        optional: true
      }),
      query(':leave', [style({ opacity: '1' }), animate('250ms ease-in-out', style({ opacity: '0' }))], {
        optional: true
      })
    ])
  ])
]);

export const fadeInAnimation = trigger('fadeInAnimation', [fadeInTransition]);
export const fadeInOutAnimation = trigger('fadeInOutAnimation', [fadeInTransition, fadeInOutTransition]);
