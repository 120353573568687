import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DocumentExplorerModule } from './modules/document-explorer/document-explorer.module';
import { ErrorPageModule } from './modules/error-page/error-page.module';
import { MaterialModule } from './modules/material.module';
import { MessageModule } from './modules/message/message.module';
import { SpinnerOverlayModule } from './modules/spinner-overlay/spinner-overlay.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    MessageModule,
    OverlayModule,
    SpinnerOverlayModule,
    ErrorPageModule,
    DocumentExplorerModule,
    AppRoutingModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
