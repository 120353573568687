<div *ngIf="messageService.messages$ | async as message" class="message-handler-wrapper">
  <div
    class="message-header-line"
    [ngClass]="{
      error: message.isError,
      success: !message.isError
    }"
  ></div>
  <div class="message-container">
    <div
      class="message"
      [ngClass]="{
        error: message.isError,
        success: !message.isError
      }"
    >
      <mat-icon *ngIf="!message.isError">check_circle_outline</mat-icon>
      <mat-icon *ngIf="message.isError">highlight_off</mat-icon>
      <span>{{ message.title }}</span>
      <p>{{ message.content }}</p>

      <button mat-icon-button disableRipple="true" (click)="onDismissMessageClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
