import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { MessageComponent } from './components/message-component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [MessageComponent],
  exports: [MessageComponent]
})
export class MessageModule {}
