import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'document-explorer-overview-expansion-panel',
  templateUrl: './document-explorer-overview-expansion-panel.component.html',
  styleUrls: ['./document-explorer-overview-expansion-panel.component.scss']
})
export class DocumentExplorerOverviewExpansionPanelComponent {
  @HostBinding('class') readonly className = 'document-explorer-overview-expansion-panel';

  /** Determines if the panel is expanded or collapsed. */
  @Input()
  public expanded: boolean;

  /** The title of the panel. */
  @Input()
  public title: string;
}
