import { Pipe, PipeTransform } from '@angular/core';

/** Adds a default value if no value was found. */
@Pipe({
  name: 'default'
})
export class DefaultPipe implements PipeTransform {
  transform(value: any, defaultValue: string | number = '--'): string {
    return (value ? value : defaultValue).toString();
  }
}
