import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'document-explorer-toolbar',
  templateUrl: './document-explorer-toolbar.component.html',
  styleUrls: ['./document-explorer-toolbar.component.scss']
})
export class DocumentExplorerToolbarComponent {
  @HostBinding('class') readonly className = 'document-explorer-toolbar';

  /** The fullname of the currently logged-in user. */
  @Input()
  public profileDisplayName: string;

  /** The profile image if avaliable of the currently logged-in user. */
  @Input()
  public base64ProfileImage: string;

  /** The initials of the currently logged-in user for use if there is no profile image. */
  @Input()
  public profileInitials: string;
}
