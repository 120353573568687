import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Message {
  title?: string;
  content?: string;
  isError?: boolean;
  disableTimeout?: boolean;
  displayTimeout?: number;
}

@Injectable({ providedIn: 'root' })
export class MessageService implements OnDestroy {
  private readonly _messageDisplayTimeout = 5000;

  private _message = new BehaviorSubject<Message>(null);
  public messages$ = this._message.asObservable();

  public constructor() {}

  public ngOnDestroy(): void {
    this._message.complete();
  }

  public showMessage(message: Message) {
    const adjustedTitle = message.title ?? (message.isError ? 'Error!' : 'Success!');
    const adjustedMessage = {
      title: adjustedTitle,
      content: message?.content,
      isError: message?.isError,
      disableTimeout: message?.disableTimeout,
      displayTimeout: message?.displayTimeout
    };
    this._message.next(adjustedMessage);

    if (!message?.disableTimeout) {
      setTimeout(() => {
        this.dismissMessage();
      }, message?.displayTimeout ?? this._messageDisplayTimeout);
    }
  }

  public dismissMessage(): void {
    this._message.next(null);
  }
}
