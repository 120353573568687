import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: 'base-component'
})
export abstract class BaseComponent implements OnDestroy {
  protected readonly _destroy: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
